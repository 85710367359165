<template>
  <div id="aboutComp">
    <header-comp></header-comp>
    <section class="topBg">
      <h3>关于我们</h3>
      <p>关于公司发展历程和公司资料简介</p>
      <img :src="url" alt="关于我们" />
    </section>
    <section class="fzlc" id="fzlc">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
        <swiper-slide v-for="item in aboutImgList" :key="item.id">
          <img :src="item.coverUrl" :alt="item.remark" class="aboutBanner" />
        </swiper-slide>
        <span class="line" slot="line"></span>
        <div class="swiper-pagination swiper-pagination-bullets" slot="pagination">
        </div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <span class="line"></span>
    </section>
    <section class="ssc" id="sscjs">
      <img :src="ssc_url" alt="收拾车" v-if="!isMobile" />
      <img :src="ssc_urlMobile1" alt="收拾车" v-if="isMobile" />
      <img :src="ssc_urlMobile2" alt="收拾车" v-if="isMobile" />
    </section>
    <footer-comp></footer-comp>
  </div>
</template>
<script type="text/javascript">
import headerComp from '@/views/components/headerComp'
import footerComp from '@/views/components/footerComp'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getAboutUsList } from '@/api/index'
import mixins from '@/mixins'
export default {
  name: "aboutComp",
  mixins: [mixins],
  components: {
    headerComp,
    footerComp,
    swiper, swiperSlide
  },
  data() {
    return {
      url: require('../assets/images/aboutBg.png'),
      img1: require('../assets/images/aboutBanner1.png'),
      img2: require('../assets/images/aboutBanner1.png'),
      ssc_url: require('../assets/images/about_ssc.png'),
      ssc_urlMobile1: require('../assets/images/mobile/guanyu_gongsijieshao_normal.png'),
      ssc_urlMobile2:require('../assets/images/mobile/guanyu_gongsitwo_normal.png'),
      swiperOptions: {
        effect: 'fade',//slide(默认)、fade、cube、coverflow、flip
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: 0,
        centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slideToClickedSlide: true, //true:点击slide会过渡到这个slide,默认false
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return `</span><span class="${className} swiper-pagination-bullet-custom">${this.yearList[index]}</span>`
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        height: window.innerHeight
      },
      aboutImgList: [],
      yearList: []
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  methods: {
    async getData() {
      const result = await getAboutUsList()
      this.aboutImgList = result.data.records
      if (this.aboutImgList && this.aboutImgList.length > 0) {
        this.aboutImgList.forEach(item => {
          this.yearList.push(item.year)
        })
      }
    },
  },
  created() {
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import '../assets/css/style.default.css';
@import '../assets/css/custom.css';

#aboutComp {
  position: relative;
  width: 100%;
}

@media (min-width:992px) {
  #aboutComp {
    position: relative;
    padding-top: 182px;
    width: 100%;
  }
}
.topBg {
  width: 100%;
  height: 240px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.contactUs {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 65% 100%;
  }

  @media (min-width:992px) {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3,
  p {
    position: absolute;
    left: 50px;
  }

  h3 {
    font-size: 32px;
    top: 82px;
  }

  p {
    font-size: 16px;
    top: 138px;
  }

  @media (min-width:992px) {

    h3,
    p {
      position: absolute;
      left: 360px;
    }

    h3 {
      font-size: 32px;
      top: 82px;
    }

    p {
      font-size: 16px;
      top: 138px;
    }
  }
}
.fzlc {
  width: 100%;
  margin-top: 4.167vw;
  min-height: 35.833vw;
  position: relative;

  .line {
    width: 100%;
    height: 1px;
    background: #979797;
    position: absolute;
    bottom: 6.9vw;
    z-index: 1;
  }

  @media (min-width: 992px) {
    .line {
      width: 100%;
      height: 1px;
      background: #979797;
      position: absolute;
      bottom: 7.813vw;
      z-index: 1;
    }
  }
}

.swiper-container {
  max-width: 1200px;
  width: 100%;
  height: 51.833vw;
  z-index: 999;

  .swiper-wrapper {
    max-width: 60.375vw;
    width: 100%;
    height: 36.833vw;


    .swiper-slide {
      height: 100%;

      .aboutBanner {
        width: 57.667vw;
        height: 36.833vw;
        margin: 0 auto;
        display: block;
      }
    }
  }


  .swiper-pagination {
    width: 100%;
    height: 5.417vw;
    bottom: 5.208vw;
    z-index: 999;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: url('../assets/images/jt_active_prev.png');
    background-size: 100% 100%;
    width: 8vw;
    height: 8vw;
  }

  .swiper-button-next {
    background: url('../assets/images/jt_active_next.png');
    background-size: 100% 100%;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after,
  .swiper-button-next:before {
    content: '';
  }

  .swiper-button-prev.swiper-button-disabled {
    background: url('../assets/images/jt_prev.png');
    background-size: 100% 100%;

  }

  .swiper-button-next.swiper-button-disabled {
    background: url('../assets/images/jt_next.png');
    background-size: 100% 100%;
  }

  /deep/.swiper-pagination-bullet-custom {
    width: 5.167vw;
    height: 5.167vw;
    line-height: 5.167vw;
    text-align: center;
    margin: 0 1.563vw;
    background: #fff;
    box-shadow: 0 0 0.417vw 0.156vw rgba(0, 0, 0, 0.16);
    opacity: 1;
    font-size: 1.5vw;

    &.swiper-pagination-bullet-active {
      width: 6vw;
      height: 6vw;
      line-height: 6vw;
      opacity: 1;
      color: '#fff';
      background: #FADB14;
      font-size: 1.8vw;
    }
  }
}

@media (min-width:992px) {
  .swiper-container {
    max-width: 1200px;
    width: 100%;
    height: 35.833vw;
    z-index: 999;

    .swiper-wrapper {
      max-width: 44.375vw;
      width: 100%;
      height: 20.833vw;


      .swiper-slide {
        height: 100%;

        .aboutBanner {
          width: 41.667vw;
          height: 20.833vw;
          margin: 0 auto;
          display: block;
        }
      }
    }


    .swiper-pagination {
      width: 100%;
      height: 5.417vw;
      bottom: 5.208vw;
      z-index: 999;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background: url('../assets/images/jt_active_prev.png');
      background-size: 100% 100%;
      width: 4.896vw;
      height: 4.896vw;
    }

    .swiper-button-next {
      background: url('../assets/images/jt_active_next.png');
      background-size: 100% 100%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after,
    .swiper-button-next:before {
      content: '';
    }

    .swiper-button-prev.swiper-button-disabled {
      background: url('../assets/images/jt_prev.png');
      background-size: 100% 100%;

    }

    .swiper-button-next.swiper-button-disabled {
      background: url('../assets/images/jt_next.png');
      background-size: 100% 100%;
    }

    /deep/.swiper-pagination-bullet-custom {
      width: 4.167vw;
      height: 4.167vw;
      line-height: 4.167vw;
      text-align: center;
      margin: 0 1.563vw;
      background: #fff;
      box-shadow: 0 0 0.417vw 0.156vw rgba(0, 0, 0, 0.16);
      opacity: 1;
      font-size: 0.833vw;

      &.swiper-pagination-bullet-active {
        width: 5.417vw;
        height: 5.417vw;
        line-height: 5.417vw;
        opacity: 1;
        color: '#fff';
        background: #FADB14;
        font-size: 1.25vw;
      }
    }
  }
}

.ssc {
  width: 100%;

  img {
    width: 100%;
    // height: 770px;
    object-fit: cover;
  }
}
</style>
